import { useState, useEffect } from "react";
import axios from "axios";
import { PuffLoader } from "react-spinners";

const TopicCard = ({
  topicId,
  cardTitle,
  imageSrc,
  numOfColumns,
  buttonStyle,
  onClickTopic,
  indexingTaskId,
  onDeleteTopic,
  onEditTopicTitle
}) => {
  const [isIndexingDone, setIsIndexingDone] = useState(true);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const handleDelete = () => {
    setShowDeleteConfirmation(true);
  };

  const confirmDelete = () => {
    setShowDeleteConfirmation(false);
    onDeleteTopic(topicId);
  };


  const [showEditModal, setShowEditModal] = useState(false);
  const [editedTopicName, setEditedTopicName] = useState(cardTitle);

  const handleEdit = () => {
    setShowEditModal(true);
  };

  const handleInputChange = (event) => {
    setEditedTopicName(event.target.value);
  };

  const handleSave = () => {
    // alert(editedTopicName);
    // Call a function to save the edited topic name
    onEditTopicTitle(topicId, editedTopicName);

    // Close the modal
    setShowEditModal(false);
  };

  const handleCancel = () => {
    // Reset the edited topic name and close the modal
    setEditedTopicName(cardTitle);
    setShowEditModal(false);
  };
  // useEffect(() => {
  //   if (indexingTaskId) {
  //     const interval = setInterval(() => {
  //       axios
  //         .get("/api/celery_indexing/status/" + indexingTaskId)
  //         .then((response) => {
  //           if (response.data.state === "SUCCESS") {
  //             clearInterval(interval);
  //             setIsIndexingDone(true);
  //           }
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //         });
  //     }, 10000);

  //     return () => clearInterval(interval);
  //   } else {
  //     setIsIndexingDone(true);
  //   }
  // }, [indexingTaskId]);

  return (
    <div className={"col-md-" + numOfColumns}>
      <div className="card"> 
      <button
          type="button"
          className="btn btn-danger btn-sm"
          style={{ position: "absolute", top: "10px", right: "10px", backgroundColor: "#5a5f99", borderColor:"#5a5f99"}}
          onClick={handleDelete}
        >
          <i className="fa fa-trash-o"></i>
          {/* X fa-trash-o */}
        </button>

        <button
          type="button"
          className="btn btn-danger btn-sm"
          style={{ position: "absolute", top: "10px", left: "10px", backgroundColor: "#5a5f99", borderColor:"#5a5f99"}}
          onClick={handleEdit}
        >
          <i className="fa fa-edit"></i>
          {/* X fa-trash-o */}
        </button>

        {showDeleteConfirmation && (
          <div className="modal" style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Confirm Deletion</h5>
                  {/* <button
                    type="button"
                    className="btn-close"
                    onClick={() => setShowDeleteConfirmation(false)}
                  ></button> */}
                </div>
                <div className="modal-body">
                  <p>Are you sure you want to delete this topic?</p>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => setShowDeleteConfirmation(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={confirmDelete}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Edit modal */}
        {showEditModal && (
          <div className="modal" style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Edit Topic Name</h5>
                  {/* <button
                    type="button"
                    className="btn-close"
                    onClick={handleCancel}
                  ></button> */}
                </div>
                <div className="modal-body">
                  <input
                    type="text"
                    value={editedTopicName}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSave}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}


        <img className="card-img-top" src={`https://images.unsplash.com/photo-1610116306796-6fea9f4fae38?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2670&q=80`} alt={cardTitle} />
        <div className="card-body">
          <h4 className="card-title mb-3">{cardTitle}</h4>
          {!isIndexingDone && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "5px",
              }}
            >
              <PuffLoader color="#ff602e" loading={true} size={25} />
              <p
                className="card-text"
                style={{ color: "black", marginLeft: "20px" }}
              >
                Creating your AI study partner. This is a one-time process Only and
                may take a few minutes. Please wait...
              </p>
            </div>
          )}
          <button
            type="button"
            onClick={() => {
              onClickTopic(topicId);
            }}
            className={"btn btn-warning btn-sm"}
            disabled={!isIndexingDone}
            style={{backgroundColor:'#5a5f99', borderColor:"#5a5f99", color:'white'}}
          >
            <i className="fa fa-arrow-circle-o-right"></i>&nbsp; Let's Study
          </button>
          {/* <hr/> */}
          {/* <br/><br/> */}
          {/* <a href="#" style={{ color: '#ff6600', textDecoration: 'underline', fontSize:'11px', borderColor:"#5a5f99", color:'#5a5f99' }}>My Flashcard Library</a> */}
        </div>
      </div>
    </div>
  );
};

TopicCard.defaultProps = {
  cardTitle: "Card Title",
  cardDescription: "Card Description",
  numOfColumns: 2, //default set to 3. Possible 1,2,3...,12
  buttonStyle: "primary",
  indexingTaskId: null,
};

export default TopicCard;
