// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDAc1bQG-Eujf85HZtBwiebZYKg077XKqg",
  authDomain: "edusina-7cabe.firebaseapp.com",
  databaseURL: "https://edusina-7cabe-default-rtdb.firebaseio.com",
  projectId: "edusina-7cabe",
  storageBucket: "edusina-7cabe.firebasestorage.app",
  messagingSenderId: "667539671596",
  appId: "1:667539671596:web:330d65fd19327dbba41f89",
  measurementId: "G-CDKQE3DN9L"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
