import { useContext, createContext, useEffect, useState } from "react";
import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { auth } from "./firebase";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});

  // const googleSignIn = () => {
  //   const provider = new GoogleAuthProvider();
  //   // signInWithPopup(auth, provider);
  //   const result = signInWithRedirect(auth, provider);
  //   console.log("the user: ", result.user);
  // };

  const googleSignIn = async () => {
    try {
      const googleProvider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, googleProvider);

      if(result.user){
        // console.log("result.user: ", result.user);
        // localStorage.setItem("x-access-tokens", result.user.accessToken);
        // route.push("/dashboard");
        window.location.href = '/home';
      }else{
        window.location.href = '/login';
      }


    } catch (error) {
      console.log(error);
    }
  };

  const logOut = () => {
    // localStorage.removeItem("x-access-tokens");
    signOut(auth);
  };

  // useEffect(() => {
  //   const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
  //     setUser(currentUser);
  //     console.log("CURRENT USER:", currentUser);
  //     if (currentUser != null) {
  //       localStorage.setItem("x-access-tokens", currentUser.accessToken);
  //     }
  //   });
  //   return () => {
  //     unsubscribe();
  //   };
  // }, []);


    // Effect to listen for Firebase auth state changes
    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in
          setUser(user);
          // console.log("user:: +=>", user);
        } else {
          // User is signed out
          setUser(null);
          // console.log("user:: -=>", user);
        }
      });
  
      // Clean up the listener on unmount
      return () => unsubscribe();
    }, []);

  return (
    <AuthContext.Provider value={{ googleSignIn, logOut, user }}>
      {children}
    </AuthContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(AuthContext);
};
