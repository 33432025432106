import { UserAuth } from "../context/AuthContext";

const HomeLeftPanel2 = ({ currentPage }) => {
  const { user } = UserAuth();
  console.log(user);
  return (
    <aside id="left-panel" className="left-panel">
      <nav className="navbar navbar-expand-sm navbar-default">
        {/* <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
          <img
            style={{ height: '50%', width:'50%' }}
            src={user.photoURL}
            alt="User Icon"
          />
          <p style={{ marginTop: '10px', textAlign: 'center' }}>{user.displayName}</p>
        </div> */}
        <br />
        <div id="main-menu" className="main-menu collapse navbar-collapse">
          <ul className="nav navbar-nav">

            <li class="">
              <a href="/home" style={{ fontFamily: "'Comic Sans MS', cursive", fontSize: '18px' }}>
                <i className="menu-icon fa fa-home" style={{ fontSize: '18px' }}></i>My Schools
              </a>
            </li>
            <li class="">
              <a href="/ai-toolbox" style={{ fontFamily: "'Comic Sans MS', cursive", fontSize: '18px' }}><i class="menu-icon fa fa-th" style={{ fontSize: '18px' }}></i>Tools</a>
            </li>
            <li class="">
              <a href="/community" style={{ fontFamily: "'Comic Sans MS', cursive", fontSize: '18px' }}><i class="menu-icon fa fa-users" style={{ fontSize: '18px' }}></i>Community</a>
            </li>
            <li style={{ position: "fixed", bottom: "0" }}>
              <a href="/add-new-student" style={{ fontFamily: "'Comic Sans MS', cursive", fontSize: '18px' }}><i class="menu-icon fa fa-plus" style={{ fontSize: '18px' }}></i> Create New School</a>
            </li>
            {/* <li class="menu-item-has-children dropdown">
              <a href="#" class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i class="menu-icon fa fa-book"></i>Lesson Plan</a>
              <ul class="sub-menu children dropdown-menu">
                <li><i class="fa fa-user"></i><a href="/student">Tamzid Azad</a></li>
                <li><i class="fa fa-user"></i><a href="ui-badges.html">Golam Mostaeen</a></li>
              </ul>
            </li>
            <li class="menu-item-has-children dropdown">
              <a href="#" class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <i class="menu-icon fa fa-user"></i>Students</a>
              <ul class="sub-menu children dropdown-menu">
                <li><i class="fa fa-user"></i><a href="/student">Tamzid Azad</a></li>
                <li><i class="fa fa-user"></i><a href="ui-badges.html">Golam Mostaeen</a></li>
              </ul>
            </li>
            <li class="">
              <a href="/add-new-student"><i class="menu-icon fa fa-plus"></i>Add New Student </a>
            </li> */}

          </ul>

          {/* <ul
            className="nav navbar-nav"
            style={{ position: "fixed", bottom: "0" }}
          >
            <li class="menu-title">More</li>
            <li>
              <a href={`#`} style={{ color: "#ffc007", fontWeight: "bold" }}>
                <i className="menu-icon fa fa-plus"></i>Upgrade to Plus
              </a>
              <a href={`https://discord.gg/qXbfRhqFUf`} target="_blank">
                <i className="menu-icon fa fa-external-link-square"></i>Join the
                Community (Discord)
              </a>
            </li>
          </ul> */}
        </div>
      </nav>
    </aside>
  );
};

export default HomeLeftPanel2;
