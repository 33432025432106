import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Header from "../../components/Header";
import axios from "axios";
import { UserAuth } from "../../context/AuthContext";

import HomeLeftPanel from "../../components/HomeLeftPanel";

const Lesson = () => {
    const { childId, gradeId, subjectId, chapterId, lessonId } = useParams();
    const { logOut, user } = UserAuth();
    const [lessonData, setLessonData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editedLessonText, setEditedLessonText] = useState("");
    const [editedLessonName, setEditedLessonName] = useState(""); // New state for lesson name
    const [isPrompting, setIsPrompting] = useState(false);
    const [aiPrompt, setAiPrompt] = useState("");

    const [showQuiz, setShowQuiz] = useState(false);
    const [quizData, setQuizData] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedOption, setSelectedOption] = useState(null);
    const [isCorrect, setIsCorrect] = useState(null);
    const [correctCount, setCorrectCount] = useState(0);

    const dummyQuizData = [
        {
            question: "What is the capital of France?",
            options: ["Berlin", "Madrid", "Paris", "Rome"],
            correctAnswer: 2,
        },
        {
            question: "Which planet is known as the Red Planet?",
            options: ["Earth", "Mars", "Venus", "Jupiter"],
            correctAnswer: 1,
        },
        {
            question: "What is the largest mammal?",
            options: ["Elephant", "Blue Whale", "Giraffe", "Rhino"],
            correctAnswer: 1,
        },
    ];



    useEffect(() => {
        const fetchLesson = async () => {
            try {
                setLoading(true);
                const response = await axios.get(
                    `/api/lesson/${childId}/${gradeId}/${subjectId}/${chapterId}/${lessonId}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            "x-access-tokens": user?.accessToken,
                        },
                    }
                );
                setLessonData(response.data);
            } catch (err) {
                setError(err.message || "An error occurred while fetching the lesson.");
            } finally {
                setLoading(false);
            }
        };

        fetchLesson();
    }, [childId, gradeId, subjectId, chapterId, lessonId, user]);

    const handleSaveEdit = async () => {
        try {
            const response = await axios.put(
                `/api/lesson/${childId}/${gradeId}/${subjectId}/${chapterId}/${lessonId}`,
                { lesson_text: editedLessonText, lesson_name: editedLessonName },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-tokens": user?.accessToken,
                    },
                }
            );
            setLessonData((prev) => ({
                ...prev,
                lesson_text: editedLessonText,
                name: editedLessonName,
            }));
            setIsEditing(false);
        } catch (err) {
            alert("Failed to save changes: " + (err.message || "Unknown error"));
        }
    };

    const handleRegenerateContent = async () => {
        setLessonData((prev) => ({
            ...prev,
            lesson_text: "<h5>Regenrating lesson with your prompt. Please wait...</h5>",
        }));

        try {
            const response = await axios.post(
                `/api/generate/lesson/${childId}/${gradeId}/${subjectId}/${chapterId}/${lessonId}`,
                { prompt: aiPrompt },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-tokens": user?.accessToken,
                    },
                }
            );

            setLessonData((prev) => ({
                ...prev,
                lesson_text: response.data.lesson_text,
            }));

            // setIsEditing(true);
            setIsPrompting(false);
        } catch (err) {
            alert("Failed to generate content: " + (err.message || "Unknown error"));
        }
    };


    const handleTakeQuiz = async () => {
        // setLoading(true); // Show loading state while fetching quiz
        try {
            const response = await axios.post(
                `/api/generate/quiz/${childId}/${gradeId}/${subjectId}/${chapterId}/${lessonId}`,
                { desc: lessonData.lesson_text },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-tokens": user?.accessToken,
                    },
                }
            );
            setQuizData(response.data.questions); // Set quiz data from the API response
            setShowQuiz(true); // Show quiz only after data is loaded
        } catch (err) {
            alert("Failed to load quiz: " + (err.message || "Unknown error"));
        } finally {
            setLoading(false);
        }
    };
    

    const handleOptionClick = (index) => {
        if (selectedOption !== null){
            return;
        }

        const correct = index === quizData[currentQuestionIndex].correctAnswer;
        setSelectedOption(index);
        setIsCorrect(correct);
        if (correct == true){
            setCorrectCount(correctCount+1);
        }
    };

    const handleNextQuestion = () => {
        setSelectedOption(null);
        setIsCorrect(null);
        setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    };

    const handleQuizCancel = () => {
        setShowQuiz(false);
        setCorrectCount(0);
        setCurrentQuestionIndex(0);
        setSelectedOption(null);
    };

    const styles = {
        container: {
            maxWidth: '100%',
            margin: '0 auto',
            padding: '20px',
            borderRadius: '8px',
            backgroundColor: '#f9f9f9',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        },
        header: {
            textAlign: 'center',
            marginBottom: '20px',
            color: '#333',
        },
        contentContainer: {
            marginBottom: '20px',
            padding: '25px',
            borderRadius: '5px',
            backgroundColor: '#fff',
            border: '1px solid #ccc',
        },
        buttonContainer: {
            display: 'flex',
            gap: '10px',
            marginTop: '15px',
        },
        button: {
            padding: '3px 3px',
            borderRadius: '5px',
            border: 'none',
            cursor: 'pointer',
            backgroundColor: '#555',
            color: '#fff',
        },
        textarea: {
            width: '100%',
            height: '600px',
            borderRadius: '5px',
            border: '1px solid #ccc',
            padding: '10px',
            fontSize: '14px',
        },
        quizContainer: {
            marginTop: "20px",
            padding: "20px",
            border: "1px solid #ccc",
            borderRadius: "8px",
            backgroundColor: "#fff",
        },
        question: {
            fontSize: "18px",
            marginBottom: "10px",
        },
        option: {
            padding: "10px",
            marginBottom: "10px",
            border: "1px solid #ccc",
            borderRadius: "5px",
            cursor: "pointer",
        },
        correct: {
            backgroundColor: "#d4edda",
        },
        incorrect: {
            backgroundColor: "#f8d7da",
        },
    };

    return (
        <>
            <div id="right-panel" className="right-panel">
                <div className="content">
                    <div className="animated fadeIn">
                        <Header />
                        <HomeLeftPanel currentPage={"topics"} />

                        <div className="row">
                            <div className={"col-md-12"}>
                                <div style={styles.container}>
                                    {loading ? (
                                        <h5>Your personalized study materials are being prepared using AI! Please wait a moment...</h5>
                                    ) : error ? (
                                        <h5>Error: {error}</h5>
                                    ) : (
                                        <>
                                            {isEditing ? (
                                                <input
                                                    style={{ ...styles.textarea, height: '50px' }}
                                                    type="text"
                                                    value={editedLessonName}
                                                    onChange={(e) => setEditedLessonName(e.target.value)}
                                                />
                                            ) : (
                                                <h4>{lessonData.name}</h4>
                                            )}
                                            {isEditing ? (
                                                <textarea
                                                    style={styles.textarea}
                                                    value={editedLessonText}
                                                    onChange={(e) => setEditedLessonText(e.target.value)}
                                                />
                                            ) : (
                                                <div
                                                    style={styles.contentContainer}
                                                    dangerouslySetInnerHTML={{ __html: lessonData.lesson_text }}
                                                />
                                            )}
                                            <div style={styles.buttonContainer}>
                                                <button
                                                    style={styles.button}
                                                    onClick={() => {
                                                        setIsEditing(true);
                                                        setEditedLessonText(lessonData.lesson_text);
                                                        setEditedLessonName(lessonData.name);
                                                    }}
                                                >
                                                    Edit
                                                </button>
                                                <button
                                                    style={styles.button}
                                                    onClick={() => setIsPrompting(true)}
                                                >
                                                    Regenerate with Prompt
                                                </button>

                                                <button
                                                    style={styles.button}
                                                    onClick={() => handleTakeQuiz()}
                                                >
                                                    Take Quiz
                                                </button>
                                            </div>
                                            {isEditing && (
                                                <div style={styles.buttonContainer}>
                                                    <button style={styles.button} onClick={handleSaveEdit}>
                                                        Save
                                                    </button>
                                                    <button
                                                        style={{ ...styles.button, backgroundColor: '#dc3545' }}
                                                        onClick={() => setIsEditing(false)}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            )}
                                            {isPrompting && (
                                                <div style={{ marginTop: '20px' }}>
                                                    <textarea
                                                        style={{ ...styles.textarea, height: "200px" }}
                                                        placeholder="Enter AI prompt here..."
                                                        value={aiPrompt}
                                                        onChange={(e) => setAiPrompt(e.target.value)}
                                                    />
                                                    <div style={styles.buttonContainer}>
                                                        <button style={styles.button} onClick={handleRegenerateContent}>
                                                            Submit Prompt
                                                        </button>
                                                        <button
                                                            style={{ ...styles.button, backgroundColor: '#dc3545' }}
                                                            onClick={() => setIsPrompting(false)}
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            )}

                                            {showQuiz && quizData.length === 0 && setQuizData(dummyQuizData)}
                                            {showQuiz && quizData.length > 0 && (
                                                <div style={styles.quizContainer}>
                                                    Correct: {correctCount} of {quizData.length} ({(correctCount * 100 / quizData.length).toFixed(2)}%)
                                                    <br/><br/>
                                                    <div style={styles.question}>
                                                        Q{currentQuestionIndex+1}: {quizData[currentQuestionIndex].question}
                                                    </div>
                                                    {quizData[currentQuestionIndex].options.map((option, index) => (
                                                        <div
                                                            key={index}
                                                            style={{
                                                                ...styles.option,
                                                                ...(selectedOption === index
                                                                    ? isCorrect
                                                                        ? styles.correct
                                                                        : styles.incorrect
                                                                    : {}),
                                                            }}
                                                            onClick={() => handleOptionClick(index)}
                                                        >
                                                            {option}
                                                        </div>
                                                    ))}
                                                    {selectedOption !== null && (
                                                        <button style={styles.button} onClick={handleNextQuestion}>
                                                            Next
                                                        </button>
                                                    )}

                                                <button style={{...styles.button, margin:'3px'}} onClick={handleQuizCancel}>
                                                            Cancel
                                                        </button>
                                                </div>
                                            )}

                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Lesson;
