import { useState, useEffect } from "react";
import axios from "axios";
// import LeftPanel from "../../components/LeftPanel";
import Header from "../../components/Header";

import HomeLeftPanel from "../../components/HomeLeftPanel";

import TopicCard from "../../components/cards/TopicCard";

import { UserAuth } from "../../context/AuthContext";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Dashboard = () => {
  

  return (
    <>
      <div id="right-panel" className="right-panel">
        <div className="content">
          <div className="animated fadeIn">
            <Header />
            <HomeLeftPanel currentPage={"topics"} />

            <div className="row">
              <div className={"col-md-2"}>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
