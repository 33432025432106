import { useState, useEffect } from "react";

import axios from "axios";
// import LeftPanel from "../../components/LeftPanel";
import Header from "../../components/Header";
import HomeLeftPanel from "../../components/HomeLeftPanel";
import { UserAuth } from "../../context/AuthContext";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from 'react-router-dom'; // for routing
import StudentCard from "../../components/cards/StudentCard";


function Home() {
    const navigate = useHistory();
    const { logOut, user } = UserAuth();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [students, setStudents] = useState([]);

    useEffect(() => {
        // Function to fetch curriculum
        const fetchCurriculum = async () => {
            try {

                const response = await axios.get(`/api/user/`, {
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-tokens": user?.accessToken,
                    },
                });

                setStudents(response.data.children);
                setLoading(false);

            } catch (err) {
                console.error("Error fetching curriculum:", err);
                setError(err.message || "Failed to fetch curriculum");
            } finally {
                setLoading(false);
            }
        };

        fetchCurriculum();
    }, [user]);







    const handleClick = (studentId) => {
        navigate.push(`/student/${studentId}`);
    };


    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;


    return (
        <>
            <div id="right-panel" className="right-panel">
                <div className="content">
                    <div className="animated fadeIn">
                        <Header />
                        <HomeLeftPanel currentPage={"topics"} />

                        { loading && !students && ( <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center', // Horizontally center
                                alignItems: 'center',    // Vertically center
                                height: '100vh',         // Full viewport height to center vertically
                            }}
                        >

                            <div style={{ padding: '30px', textAlign: 'center', marginTop: '-30vh', fontFamily: "'Comic Sans MS', cursive", fontSize: '18px' }}>
                                Hi {user.displayName}, this is your dashboard where you can create or access up to 3 separate homeschools.
                                <br />
                                You can access each of your schools separately to manage curriculum, schedules, student info, grades, lesson plans, and more.

                            </div>
                            <button
                                style={{
                                    backgroundColor: '#000',
                                    color: 'white',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderRadius: '5px',
                                    padding: '10px 20px',
                                    border: 'none',
                                    cursor: 'pointer',
                                    fontFamily: "'Comic Sans MS', cursive",
                                    fontSize: '18px'
                                }}
                                onClick={() => {
                                    window.location.href = '/add-new-student';
                                }}
                            >
                                <i className="menu-icon fa fa-plus" style={{ marginRight: '8px', }}></i> Create New School
                            </button>

                        </div>)}



                        <div className="row">
                            {Object.values(students || {}).map((child) => (
                                <StudentCard
                                    cardTitle={`${child.name}'s school`}
                                />

                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>




    );
}

const styles = {
    container: {
        maxWidth: '90%',
        margin: '0 auto',
        padding: '20px',
        borderRadius: '8px',
        backgroundColor: '#f9f9f9',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    header: {
        textAlign: 'center',
        marginBottom: '20px',
        color: '#333',
    },
    subjectContainer: {
        marginBottom: '20px',
        padding: '15px',
        borderRadius: '5px',
        backgroundColor: '#e3eaf2',
    },
    subjectHeader: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#555',
    },
    time: {
        fontSize: '14px',
        color: '#777',
        marginBottom: '10px',
    },
    contentContainer: {
        marginBottom: '10px',
        padding: '10px',
        borderRadius: '4px',
        backgroundColor: '#fff',
        border: '1px solid #ccc',
    },
    contentTitle: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#333',
    },
    contentDescription: {
        fontSize: '14px',
        color: '#666',
    },
    dueDate: {
        fontSize: '13px',
        fontStyle: 'italic',
        color: '#d9534f',
    },
    extraSection: {
        marginTop: '30px',
        padding: '15px',
        borderRadius: '5px',
        backgroundColor: '#f2f2f2',
    },
    extraHeader: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#555',
        marginBottom: '10px',
    },
    event: {
        backgroundColor: '#4A90E2',
        border: 'none',
        color: 'white',
        padding: '5px',
        borderRadius: '4px',
        fontSize: '0.9em',
        textAlign: 'center',
        fontWeight: '500',
    },
    dayCell: {
        backgroundColor: '#ffffff', // White day cells
        border: '1px solid #e5e5e5',
    },
    todayCell: {
        backgroundColor: '#eaf6ff', // Light blue for today
    },
    timeGridSlot: {
        color: '#333',
        fontWeight: 'bold',
    },
    timeGridEvent: {
        backgroundColor: '#4A90E2',
        borderRadius: '4px',
        color: '#ffffff',
    },

};

export default Home;
