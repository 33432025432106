import React from "react";
import Header from "./Header";
import { UserAuth } from "../../context/AuthContext";

import { Redirect } from "react-router-dom";

import { GoogleButton } from "react-google-button";
import styles from './css/css/style.module.css';

function Login(props) {
  // require("./css/css/style.min.css");
  const { googleSignIn, user } = UserAuth();

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
    } catch (error) {
      console.log(error);
    }
  };

  if (user) {
    return <Redirect to="/home" />;
  }

  return (
    <>
      <Header showLoginBtn={false}/>
      <div className={styles['content-wrapper']}>
        <div className={`${styles['container']} ${styles['d-flex']} ${styles['justify-content-center']}`}>
          <GoogleButton
            onClick={handleGoogleSignIn}
            style={{ width: "50%", fontSize: "20px" }}
          />
        </div>
      </div>
    </>
  );
}

export default Login;
