import React, { useState } from 'react';
import axios from "axios";

const YouTubeQA = () => {
    const [chatInput, setChatInput] = useState('');
    const [history, setHistory] = useState([]);
    const [formData, setFormData] = useState({
        language: '',
        message: '',
    });
    const [formSubmitted, setFormSubmitted] = useState(false);

    // Handle form submission
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const { language,  message } = formData;

        const newHistory = [
            ...history,
            {
                role: 'user', parts: `Based on the YouTube video: ${language} , ${message}
                DO NOT TALK ANYTHING ELSE and GIVE THE RESULT IN HTML FORMAT.` 
            },
        ];
        setHistory(newHistory);
        setFormSubmitted(true);

        try {
            console.log(newHistory);
            const response = await axios.post("/api/sendChat", {
                history: newHistory,
            });

            const modelResponse = response.data.model;

            setHistory([
                ...newHistory,
                { role: 'model', parts: modelResponse },
            ]);
        } catch (error) {
            console.error('Error sending chat:', error);
        }


        // Send the form data to the server (for example via API request)
        console.log('Form submitted:', { language,  message });

        // After successful form submission, hide the form and show a result

    };

    // Handle chat input submission
    const handleSubmitChat = async (e) => {
        e.preventDefault();
        if (chatInput.trim()) {
            const newHistory = [
                ...history,
                { role: 'user', parts: chatInput },
            ];
            setHistory(newHistory);  // Update history with user's message

            try {
                // const response = await fetch('/api/sendChat', {
                //     method: 'POST',
                //     headers: { 'Content-Type': 'application/json' },
                //     body: JSON.stringify({ history: newHistory }),
                // });

                const response = await axios.post("/api/sendChat", {
                    history: newHistory,
                });

                const modelResponse = response.data.model;
                // console.log(response.data);
                // const data = await response.json();
                // const modelMessage = data.response;  // Assuming model response is in 'response'

                // console.log(modelMessage);

                setHistory([
                    ...newHistory,
                    { role: 'model', parts: modelResponse },
                ]);
            } catch (error) {
                console.error('Error sending chat:', error);
            }

            setChatInput('');  // Clear chat input after sending
        }
    };

    // Handle form input change
    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        // <div id="right-panel" className="right-panel">
        //     <div className="content">
        //         <div className="animated fadeIn">

        

            <>
{/* <div className="col-md-4"></div> */}

            <div className="col-md-12">



                <div style={styles.formContainer}>
                    <h3>YouTube Assistant</h3>
                    <p>Generate Anything based on a given youtube video link</p>
                    <br />
                    {/* Form Section */}
                    {!formSubmitted ? (
                        <form onSubmit={handleFormSubmit} style={{ width: '900px', marginBottom: '20px' }}>
                            
                            <div>
                                <label>YouTube Video Link: *</label>
                                <input
                                    type="text"
                                    name="language"
                                    value={formData.language}
                                    onChange={handleFormChange}
                                    required
                                    style={{ width: '100%', padding: '8px', margin: '5px 0' }}
                                />
                            </div>
                            <div>
                                <label>Instructions: *</label>
                                <textarea
                                    name="message"
                                    value={formData.message}
                                    onChange={handleFormChange}
                                    required
                                    style={{ width: '100%', padding: '8px', margin: '5px 0', height: "300px" }}
                                />
                            </div>

                            
                            <button type="submit" style={{ width: '900px', padding: '10px 20px', backgroundColor: '#4CAF50', color: 'white', border: 'none', cursor: 'pointer' }}>
                                Generate
                            </button>
                        </form>
                    ) : (
                        <div style={{ marginBottom: '20px' }}>
                            {/* <h3>Form Submitted Successfully!</h3> */}
                            {/* <p>Your details have been received. Thank you!</p> */}
                        </div>
                    )}

                    {/* Chat Section */}
                    {formSubmitted ? (<div style={{ width: '900px', marginBottom: '20px' }}>
                        {/* <div style={{ height: '300px', overflowY: 'auto', border: '1px solid #ddd', padding: '10px', marginBottom: '10px' }}> */}
                        {/* Display Chat History */}
                        {history.slice(1).map((message, index) => {
                            // Define color mapping for roles
                            const roleColors = {
                                user: '#d1e7ff', // Light blue for user
                                system: '#f8d7da', // Light red for system
                                assistant: '#d4edda', // Light green for assistant
                            };

                            // Default to gray if role is not defined in the color mapping
                            const backgroundColor = roleColors[message.role] || '#f0f0f0';

                            return (
                                <div
                                    key={index}
                                    style={{
                                        backgroundColor,
                                        padding: '10px',
                                        borderRadius: '5px',
                                        margin: '5px 0',
                                    }}
                                >
                                    <>

                                    <div
                                                    style={{padding:'30px', margin:'10px'}}
                                                    dangerouslySetInnerHTML={{ __html: message.parts }}
                                                />
                                    </>
                                    {/* {message.parts} */}
                                </div>
                            );
                        })}

                        {/* </div> */}

                        <form
                            onSubmit={handleSubmitChat}
                            style={{
                                position: 'fixed',
                                bottom: 0,
                                left: 0,
                                transform: 'translateX(30%)',
                                width: '70%',
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '10px',
                                backgroundColor: '#fff',
                                padding: '10px',
                                boxShadow: '0 -2px 5px rgba(0,0,0,0.1)',
                                zIndex: 1000
                            }}
                        >
                            <input
                                type="text"
                                value={chatInput}
                                onChange={(e) => setChatInput(e.target.value)}
                                placeholder="Type a message..."
                                required
                                style={{
                                    flex: 1,
                                    padding: '8px',
                                    border: '1px solid #ddd',
                                    borderRadius: '4px'
                                }}
                            />
                            <button
                                type="submit"
                                style={{
                                    padding: '8px 16px',
                                    backgroundColor: '#007BFF',
                                    color: 'white',
                                    border: 'none',
                                    borderRadius: '4px',
                                    cursor: 'pointer'
                                }}
                            >
                                Send
                            </button>
                        </form>

                    </div>) : <></>}
                </div>
            </div>
            </>
        // </div></div></div>
    );
};


const styles = {
    formContainer: {
        maxWidth: "100%",
        // margin: "20px auto",
        padding: "20px",
        borderRadius: "8px",
        backgroundColor: "#fefefe",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    },
    header: {
        textAlign: "center",
        marginBottom: "20px",
        fontSize: "24px",
        fontWeight: "bold",
        color: "#333",
    },
    formGroup: {
        marginBottom: "15px",
    },
    label: {
        display: "block",
        marginBottom: "5px",
        fontWeight: "bold",
        color: "#555",
        fontSize: "16px",
    },
    input: {
        width: "100%",
        padding: "8px",
        borderRadius: "4px",
        border: "1px solid #ccc",
        fontSize: "16px",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "20px",
    },
    button: {
        padding: "10px 20px",
        backgroundColor: "#007BFF",
        color: "#fff",
        border: "none",
        borderRadius: "4px",
        fontSize: "16px",
        cursor: "pointer",
        transition: "background-color 0.3s ease",
    },
    buttonHover: {
        backgroundColor: "#0056b3",
    },
    subjectList: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        // marginLeft: "10px"
        // gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
        gap: "20px",
        // marginTop: "20px",
    },
    subjectCard: {
        padding: "10px",
        border: "1px solid #ccc",
        borderRadius: "4px",
        backgroundColor: "#f9f9f9",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    },
    subjectCardHeader: {
        fontSize: "18px",
        fontWeight: "bold",
        marginBottom: "10px",
        color: "#444",
    },
    checkboxContainer: {
        display: "flex",
        alignItems: "center",
        marginBottom: "8px",
    },
    checkbox: {
        marginRight: "10px",
    },
    checkboxLabel: {
        fontSize: "14px",
        color: "#333",
    },
};

export default YouTubeQA;
