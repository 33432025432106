import { useState, useEffect } from "react";
import axios from "axios";
import Header from "../../components/Header";
import HomeLeftPanel from "../../components/HomeLeftPanel";
import { UserAuth } from "../../context/AuthContext";

const AddNewStudent = () => {
  const { logOut, user } = UserAuth();
  const countryStateMap = {
    USA: ["Alabama", "Alaska", "California", "New York", "Texas"],
    Canada: ["Ontario", "Quebec", "British Columbia", "Alberta"],
    Australia: ["New South Wales", "Victoria", "Queensland"],
  };

  const [formData, setFormData] = useState({
    name: "",
    grade: "",
    startDate: "",
    endDate: "",
    studyDays: [],
    schoolDayStartTime: "",
    schoolDayEndTime: "",
    country: "",
    state: "",
    subjects: [],
  });

  const [step, setStep] = useState(1); // Controls the current form step
  const [subjectsData, setSubjectsData] = useState([]); // Stores subjects data fetched from server
  const [selectedSubjects, setSelectedSubjects] = useState([]); // Stores selected subjects and chapters

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));

    if (name === "country") {
      setFormData((prevData) => ({ ...prevData, state: "" })); // Reset state when country changes
    }
  };

  const handleStudyDaysChange = (e) => {
    const { options } = e.target;
    const selectedDays = Array.from(options)
      .filter((option) => option.selected)
      .map((option) => option.value);
    setFormData((prevData) => ({ ...prevData, studyDays: selectedDays }));
  };

  const handleStateChange = async (e) => {
    const selectedState = e.target.value;
    setFormData((prevData) => ({ ...prevData, state: selectedState }));

    const params = {
      country: formData.country,
      state: selectedState,
      grade: formData.grade,
    };
    console.log(params)

    try {
      const response = await axios.get("http://127.0.0.1:5000/ai-get-curriculum", { params });
      // setSubjectsData(response.data.subjects);
      console.log(response);
      setSubjectsData(response.data.curriculum);
      // setSubjectsData([
      //     {
      //       "name": "Mathematics",
      //       "chapters": [
      //         "Integers and Rational Numbers",
      //         "Rates and Ratios",
      //         "Proportions",
      //         "Percents",
      //         "Algebra: Expressions and Equations",
      //         "Linear Relations",
      //         "Measurement: Area and Volume",
      //         "Probability and Statistics"
      //       ]
      //     },
      //     {
      //       "name": "Language Arts",
      //       "chapters": [
      //         "Reading Comprehension",
      //         "Writing: Narrative and Descriptive",
      //         "Grammar and Sentence Structure",
      //         "Vocabulary Development",
      //         "Literary Devices",
      //         "Creative Writing",
      //         "Essay Writing",
      //         "Public Speaking"
      //       ]
      //     },
      //     {
      //       "name": "Science",
      //       "chapters": [
      //         "Cells and Systems",
      //         "Matter and Chemical Change",
      //         "Heat and Temperature",
      //         "Light and Sound",
      //         "Electrical Circuits",
      //         "Structures and Forces"
      //       ]
      //     },
      //     {
      //       "name": "Social Studies",
      //       "chapters": [
      //         "Early Societies: Indigenous Peoples of Canada",
      //         "Exploration and Colonization",
      //         "Canada's Government and Citizenship",
      //         "Globalization and Interdependence",
      //         "Human Rights and Social Justice"
      //       ]
      //     },
      //     {
      //       "name": "French",
      //       "chapters": [
      //         "Basic Vocabulary and Phrases",
      //         "Grammar: Verbs and Tenses",
      //         "Reading Comprehension",
      //         "Writing: Simple Sentences",
      //         "French Culture and Traditions"
      //       ]
      //     },
      //     {
      //       "name": "Health and Physical Education",
      //       "chapters": [
      //         "Physical Fitness and Health",
      //         "Mental and Emotional Health",
      //         "Nutrition and Healthy Eating",
      //         "Safety and First Aid",
      //         "Team Sports and Physical Activities"
      //       ]
      //     },
      //     {
      //       "name": "The Arts",
      //       "chapters": [
      //         "Visual Arts: Drawing, Painting, Sculpture",
      //         "Music: Theory and Instruments",
      //         "Drama: Acting and Performance",
      //         "Dance: Basic Movements and Choreography"
      //       ]
      //     },
      //     {
      //       "name": "Geography",
      //       "chapters": [
      //         "Physical Geography of Canada",
      //         "Climate and Weather Patterns",
      //         "Natural Resources",
      //         "Map Skills and Cartography",
      //         "Human Geography"
      //       ]
      //     }
      //   ]
      // );
    } catch (error) {
      console.error("Error fetching subjects:", error);
    }
  };

  const handleSubjectSelection = (subject, chapter) => {
    setSelectedSubjects((prev) => {
      const updated = [...prev];
      const subjectIndex = updated.findIndex((s) => s.subject === subject);
      if (subjectIndex === -1) {
        // Add new subject and chapter
        updated.push({ subject, chapters: [chapter] });
      } else {
        const chapters = updated[subjectIndex].chapters;
        if (chapters.includes(chapter)) {
          // Remove chapter if already selected
          updated[subjectIndex].chapters = chapters.filter((c) => c !== chapter);
        } else {
          // Add chapter to existing subject
          chapters.push(chapter);
        }
      }
      return updated;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const updatedFormData = {
        ...formData,
        subjects: subjectsData,  // Set subjectsData into the formData
      };

      const response = await axios.post("/api/add-new-student/", updatedFormData, {
        headers: {
          "Content-Type": "application/json",
          "x-access-tokens": user?.accessToken, //localStorage.getItem("x-access-tokens"),
        },
      });
      
      console.log("Response:", response.data);
      if (response.status === 200) {
        alert("Student added successfully!");
        setFormData({
          name: "",
          grade: "",
          startDate: "",
          endDate: "",
          studyDays: [],
          schoolDayStartTime: "",
          schoolDayEndTime: "",
          country: "",
          state: "",
          subjects: [],
        });
        setSelectedSubjects([]);
        setStep(1);
        window.location.href = '/home';
      } else {
        alert("Failed to add student.");
      }
    } catch (error) {
      console.error("Error adding student:", error);
      alert("An error occurred while adding the student.");
    }
  };

  const renderFormStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <div style={styles.formGroup}>
              <label style={styles.label}>Student Name:</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                required
                style={styles.input}
              />
            </div>
  
            <div style={styles.formGroup}>
              <label style={styles.label}>Grade:</label>
              <select
                name="grade"
                value={formData.grade}
                onChange={handleInputChange}
                required
                style={styles.input}
              >
                <option value="">Select Grade</option>
                {["Grade 1", "Grade 2", "Grade 3", "Grade 4", "Grade 5", "Grade 6", "Grade 7", "Grade 8", "Grade 9", "Grade 10", "Grade 11", "Grade 12"].map((grade) => (
                  <option key={grade} value={grade}>
                    {grade}
                  </option>
                ))}
              </select>
            </div>
  
            <div style={styles.formGroup}>
              <label style={styles.label}>Country:</label>
              <select
                name="country"
                value={formData.country}
                onChange={handleInputChange}
                required
                style={styles.input}
              >
                <option value="">Select Country</option>
                {Object.keys(countryStateMap).map((country) => (
                  <option key={country} value={country}>
                    {country}
                  </option>
                ))}
              </select>
            </div>
  
            <div style={styles.formGroup}>
              <label style={styles.label}>{formData.country === "USA" ? "State" : "Province"}:</label>
              <select
                name="state"
                value={formData.state}
                onChange={handleStateChange}
                required
                style={styles.input}
                disabled={!formData.country}
              >
                <option value="">Select {formData.country === "USA" ? "State" : "Province"}</option>
                {formData.country &&
                  countryStateMap[formData.country].map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
              </select>
            </div>


            
          </>
        );
  
      case 2:
        return (
          <>
            <div style={styles.formGroup}>
              <label style={styles.label}>School Start Date:</label>
              <input
                type="date"
                name="startDate"
                value={formData.startDate}
                onChange={handleInputChange}
                required
                style={styles.input}
              />
            </div>
  
            <div style={styles.formGroup}>
              <label style={styles.label}>School End Date:</label>
              <input
                type="date"
                name="endDate"
                value={formData.endDate}
                onChange={handleInputChange}
                required
                style={styles.input}
              />
            </div>
  
            <div style={styles.formGroup}>
              <label style={styles.label}>Preferred Days of the Week:</label>
              <select
                name="studyDays"
                value={formData.studyDays}
                onChange={handleStudyDaysChange}
                multiple
                required
                style={{ ...styles.input, height: '200px' }}
              >
                {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map((day) => (
                  <option key={day} value={day}>
                    {day}
                  </option>
                ))}
              </select>
            </div>
  
            <div style={styles.formGroup}>
              <label style={styles.label}>School Day Start Time:</label>
              <input
                type="time"
                name="schoolDayStartTime"
                value={formData.schoolDayStartTime}
                onChange={handleInputChange}
                required
                style={styles.input}
              />
            </div>

            <div style={styles.formGroup}>
              <label style={styles.label}>School Day End Time:</label>
              <input
                type="time"
                name="schoolDayEndTime"
                value={formData.schoolDayEndTime}
                onChange={handleInputChange}
                required
                style={styles.input}
              />
            </div>            
          </>
        );
  
      case 3:
        return (
          <>
            <h4 style={styles.header}>Your Curriculum</h4>
            <div style={styles.subjectList}>
              {subjectsData.length === 0 ? (
                <p>Setting up your personalized curriculum with AI based on your selections. Please wait...</p>
              ) : (
                <>
                  <h6>Please select the subjects below to set up your homeschool:</h6>
                  {subjectsData.map((subject) => (
                    <div key={subject.name} style={styles.subjectCard}>
                      <h5>{subject.name}</h5>
                      {subject.chapters.map((chapter) => (
                        <div key={chapter.name} style={styles.checkboxContainer}>
                          {/* <input
                            type="checkbox"
                            id={`${subject.name}-${chapter}`}
                            onChange={() => handleSubjectSelection(subject.name, chapter)}
                            style={styles.checkbox}
                          /> */}
                          <label htmlFor={`${subject.name}-${chapter}`} style={styles.checkboxLabel}>
                            - {chapter.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  ))}
                </>
              )}
            </div>
          </>
        );
  
      default:
        return null;
    }
  };

  return (
    <div id="right-panel" className="right-panel">
      <div className="content">
        <div className="animated fadeIn">
          <Header />
          <HomeLeftPanel currentPage={"topics"} />
          <form onSubmit={handleSubmit} style={styles.formContainer}>
            {/* <h4 style={styles.header}>Add New Student</h4> */}
            {renderFormStep()}
            <div style={styles.buttonContainer}>
              {step > 1 && (
                <button
                  type="button"
                  style={styles.button}
                  onClick={() => setStep(step - 1)}
                >
                  Previous
                </button>
              )}
              {step < 3 && (
                <button
                  type="button"
                  style={styles.button}
                  onClick={() => setStep(step + 1)}
                >
                  Next
                </button>
              )}
              {step === 3 && (
                <button type="submit" style={styles.button}>
                  Create Homeschool
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const styles = {
  formContainer: {
    maxWidth: "100%",
    margin: "20px auto",
    padding: "20px",
    borderRadius: "8px",
    backgroundColor: "#fefefe",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  header: {
    textAlign: "center",
    marginBottom: "20px",
    fontSize: "24px",
    fontWeight: "bold",
    color: "#333",
  },
  formGroup: {
    marginBottom: "15px",
  },
  label: {
    display: "block",
    marginBottom: "5px",
    fontWeight: "bold",
    color: "#555",
    fontSize: "16px",
  },
  input: {
    width: "100%",
    padding: "8px",
    borderRadius: "4px",
    border: "1px solid #ccc",
    fontSize: "16px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
  },
  button: {
    padding: "10px 20px",
    backgroundColor: "#007BFF",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    fontSize: "16px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
  buttonHover: {
    backgroundColor: "#0056b3",
  },
  subjectList: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    // marginLeft: "10px"
    // gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
    gap: "20px",
    // marginTop: "20px",
  },
  subjectCard: {
    padding: "10px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    backgroundColor: "#f9f9f9",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  subjectCardHeader: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "10px",
    color: "#444",
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
  },
  checkbox: {
    marginRight: "10px",
  },
  checkboxLabel: {
    fontSize: "14px",
    color: "#333",
  },
};

export default AddNewStudent;
