import { useState, useEffect } from "react";
import { format, parseISO, isToday, isYesterday, isTomorrow, compareAsc } from 'date-fns';
import axios from "axios";
// import LeftPanel from "../../components/LeftPanel";
import Header from "../../components/Header";

import HomeLeftPanel from "../../components/HomeLeftPanel";

import TopicCard from "../../components/cards/TopicCard";

import { UserAuth } from "../../context/AuthContext";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from 'react-router-dom'; // for routing

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

function AiToolBox() {
    const navigate = useHistory();
    const { logOut, user } = UserAuth();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [students, setStudents] = useState([]);









    const handleClick = (toolId) => {
        navigate.push(`/ai-tool/${toolId}`);
    };


    // if (loading) return <div>Loading...</div>;
    // if (error) return <div>Error: {error}</div>;


    return (
        <>
            <div id="right-panel" className="right-panel">
                <div className="content">
                    <div className="animated fadeIn">
                        <Header />
                        <HomeLeftPanel currentPage={"topics"} />

                        <div className="row">
                            <div style={{ padding: "30px" }}>
                                {/* <h4>Students List</h4> */}
                                <ul>

                                <li key={"text-leveler"}>
                                        <a
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleClick("text-leveler");
                                            }}
                                        >
                                            Text Leveler
                                        </a>
                                    </li>

                                    <li key={"text-proofreader"}>
                                        <a
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleClick("text-proofreader");
                                            }}
                                        >
                                            Text Proofreader
                                        </a>
                                    </li>

                                    <li key={"text-translator"}>
                                        <a
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleClick("text-translator");
                                            }}
                                        >
                                            Text Translater
                                        </a>
                                    </li>

                                    <li key={"text-translator"}>
                                        <a
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleClick("create-lesson");
                                            }}
                                        >
                                            Create Lesson
                                        </a>
                                    </li>

                                    <li key={"text-translator"}>
                                        <a
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleClick("text-rewriter");
                                            }}
                                        >
                                            Text Rewriter
                                        </a>
                                    </li>

                                    <li key={"text-translator"}>
                                        <a
                                            href="#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleClick("youtube-chat");
                                            }}
                                        >
                                            YouTube Chat
                                        </a>
                                    </li>

                                </ul>
                            </div>

                        </div>




                    </div>
                </div>
            </div>
        </>




    );
}

const styles = {
    container: {
        maxWidth: '90%',
        margin: '0 auto',
        padding: '20px',
        borderRadius: '8px',
        backgroundColor: '#f9f9f9',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    header: {
        textAlign: 'center',
        marginBottom: '20px',
        color: '#333',
    },
    subjectContainer: {
        marginBottom: '20px',
        padding: '15px',
        borderRadius: '5px',
        backgroundColor: '#e3eaf2',
    },
    subjectHeader: {
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#555',
    },
    time: {
        fontSize: '14px',
        color: '#777',
        marginBottom: '10px',
    },
    contentContainer: {
        marginBottom: '10px',
        padding: '10px',
        borderRadius: '4px',
        backgroundColor: '#fff',
        border: '1px solid #ccc',
    },
    contentTitle: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#333',
    },
    contentDescription: {
        fontSize: '14px',
        color: '#666',
    },
    dueDate: {
        fontSize: '13px',
        fontStyle: 'italic',
        color: '#d9534f',
    },
    extraSection: {
        marginTop: '30px',
        padding: '15px',
        borderRadius: '5px',
        backgroundColor: '#f2f2f2',
    },
    extraHeader: {
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#555',
        marginBottom: '10px',
    },
    event: {
        backgroundColor: '#4A90E2',
        border: 'none',
        color: 'white',
        padding: '5px',
        borderRadius: '4px',
        fontSize: '0.9em',
        textAlign: 'center',
        fontWeight: '500',
    },
    dayCell: {
        backgroundColor: '#ffffff', // White day cells
        border: '1px solid #e5e5e5',
    },
    todayCell: {
        backgroundColor: '#eaf6ff', // Light blue for today
    },
    timeGridSlot: {
        color: '#333',
        fontWeight: 'bold',
    },
    timeGridEvent: {
        backgroundColor: '#4A90E2',
        borderRadius: '4px',
        color: '#ffffff',
    },

};

export default AiToolBox;
